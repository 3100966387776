import * as React from "react"
import { Link } from "gatsby"

export default function Component() {


  return (
    <div className="global-wrapper">
      <header className="global-header">
        <Link className="header-link-home" to="/">
          Is This A Cyber Attack?
        </Link>
        <h1 className="main-heading">
          What Is a Cyberattack?
        </h1>
      </header>
      <article id="what">
        <blockquote>A cyber attack is a cyber operation, whether offensive or defensive, that is reasonably expected to
          cause injury or death to persons or damage or destruction to objects.<br /><br />

          <a
            href="https://www.cambridge.org/ie/academic/subjects/law/humanitarian-law/tallinn-manual-international-law-applicable-cyber-warfare?format=AR">Michael
            Schmitt et al; Tallinn Manual 1.0, pp 92</a>
        </blockquote>
        <h2>The Hyperbole Problem</h2>
        <p>The language in the press and policy conversations around cyber security incidents has gotten hyperbolic.
          Everything is "an attack", things go up
          from there to <a
            href="https://www.cyberscoop.com/cyber-pearl-harbor-911-cyberwar-hacking-leon-panetta-ciaran-martin/">"cyber
            pearl harbor"</a> or <a
            href="https://thehill.com/opinion/cybersecurity/531566-solarwinds-hack-what-we-must-do-to-avoid-the-next-attack">"cyber
            9/11"</a>.
        </p>
        <p>While it may seem like nitpicking, the major powers of the world are investing heavily into cyber warefare
          capabilities and have developed doctrine and policy on when and how those capabilities will be used.
          Militaries all over the world are planning for cyberattacks. When incidents that do not meet the level of an
          attack, are called an attack, there is political <a href="https://ccdcoe.org/uploads/2012/01/5_3_Ziolkowski_IusAdBellumInCyberspace.pdf">pressure to respond and retaliate.</a></p>
        <p>September 11th, provides a good example of why we need to use less extreme language.
          We do not call every airline hijacking an act of war, only when an airplane is hijacked
          and then used as a weapon does it cross the threshold of an attack.</p>
        <p> Likewise, we have terms for high-seas
          piracy, "holding hostage", "demanding ransom", "held captive" that allows for a spectrum of activity between
          nuisance, severe, and an attack.</p>
        <p>If we call everything from a password being stolen or website defacement an attack, how do we discuss
          malicious behavior that raises to the level of needing a military
          response? </p>
        <h2>Alternatives</h2>
        <p>
          Instead of cybersecurity events "attacks", instead consider using:
          <ul id="alt-list">
            <li>security incident</li>
            <li>compromise</li>
            <li>malicious activity</li>
            <li>servers held ransom</li>
            <li>ransomware incident</li>
            <li>data leak</li>
            <li>espionage operation</li>
            <li>intelligence collection operation</li>
          </ul>
        </p>
        <hr />
        <h4 id="credits">Credits</h4>
        <p>
          <ul id="alt-list">
            <li><a href="https://twitter.com/tinfoil_globe">tinfoil</a></li>
          </ul>
        </p>
      </article>
    </div>
  )
}
